/* ReachUsButton.css */
.reach-us-btn {
    position: fixed;
    left: 88%;
    transform: translateX(50%);
    padding: 20px 50px;
    background-color: #24444f;
    color: #fff;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    /* border-radius: 2.222em 4px; */
   

  }
  .reach-us-btn:hover {
    background-color: #24444f;
    
  }
  @media (max-width: 768px) {
    .reach-us-btn {
      left: unset;
      right: 20px; /* Adjust this value for mobile view */
      transform: translateX(0);
      top:100%; /* Adjust this value for mobile view */
    }
  }
  
 