/* .shadow-effect {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #ECECEC;
    box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
  }
  #shadow-effect p {
    font-family: inherit;
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 17px 0;
    font-weight: 300;
  }
  .img-circle {
    border-radius: 50%;
    vertical-align: middle;
    max-width: 100px;
    min-height: 100px;
    transform-style: preserve-3d;
    margin: 0 auto 17px;
  }
  #customer-testimonoals {
    margin-top: 6%;
  } */
  .testimonial-name {
    margin: -17px auto 0;
    display: table;
    width: auto;
    /* background: linear-gradient(100deg, #845EC2, #BE93FD); */
    
    /* background: linear-gradient(135deg, #ff3e00, #eaa400); */
    padding: 9px 35px;
    border-radius: 12px;
    text-align: center;
  
    
  }
  #customer-testimonoals .item {
    text-align: center;
    padding: 50px;
    margin-bottom: 0px;
    opacity: 0.2;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
  }
  #customer-testimonoals .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
  }
  /* .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #56423D;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  } */
  /* .owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center; */
  
  /* .owl-carousel .owl-dots .owl-dot{ 
    display: inline-block;
  } */
  /* .owl-carousel .owl-dots .owl-dot span{ 
    
    background: #56423D;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
  }  */
  /* YourComponent.css */

.owl-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.owl-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .shadow-effect {
  position: relative;
} */

.img-container {
  width: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
}

.testimonial-text {
  margin-top: 20px;
}

.testimonial-name {
  margin-top: 10px;
}
/* Add borders to the testimonial items */
.testimonial-item {
  border: 2px solid #000000; /* Adjust the color and thickness as needed */
  border-radius: 2px; /* Add rounded corners if desired */
  padding: 20px;
 height: 440px;
 margin-right: 0px;/* Add some padding to the items */
}
testimonial-item:hover {
  border-color: black; /* Change to your desired hover color */
}

/* Remove border for the last item to avoid extra border */
.owl-carousel .owl-item:last-child .testimonial-item {
  border: none;
}

/* Add borders to the testimonial items */
.testimonial-item {
  border: 2px solid #000000; /* Adjust the color and thickness as needed */
  border-radius: 2px; /* Add rounded corners if desired */
  padding: 20px;
 height: 470px;
 margin-right: 0px;/* Add some padding to the items */
}
testimonial-item:hover {
  border-color: black; /* Change to your desired hover color */
}

/* Remove border for the last item to avoid extra border */
.owl-carousel .owl-item:last-child .testimonial-item {
  border: none;
}
.learm_more_button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.2rem;
  padding-inline: 1.25rem;
  background-color: #ffffff;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  cursor: pointer;
  font-family: Open Sans,Sans Serif;
}


.learm_more_button:hover {
  transform: scale(1.05);
  border-color: rgba(255, 255, 255, 0.6);
  color: #000000;
}

.learm_more_button:hover .icon {
  transform: translate(4px);
}

.learm_more_button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.learm_more_button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}
.learm_more_button1 {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 10px #24444f;
  padding-block: 0.2rem;
  padding-inline: 1.25rem;
  background-color: transparent;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  cursor: pointer;
  font-family: Open Sans,Sans Serif;
  width: 250px;
}


.learm_more_button1:hover {
  transform: scale(1.05);
  border-color: #797979;
  color: #797979;
}

.learm_more_button1:hover .icon {
  transform: translate(4px);
}

.learm_more_button1:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.learm_more_button1::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}