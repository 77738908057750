/*******************************/
/********* General CSS *********/
/*******************************/
body {
    color: #797979;
    background: #ffffff;
    font-family: "Raleway", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #24444f;
}

a {
    color: #454545;
    transition: .3s;
}

a:hover,
a:active,
a:focus {
    color: #F7CAC9;
    outline: none;
    text-decoration: none;
}

.btn:focus,
.form-control:focus {
    box-shadow: none;
}

.container-fluid {
    max-width: 1512px;
}

.back-to-top {
    position: fixed;
    display: none;
    background: #F7CAC9;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 1;
    font-size: 22px;
    right: 15px;
    bottom: 15px;
    transition: background 0.5s;
    z-index: 9;
}

.back-to-top:hover {
    background: #24444f;
}

.back-to-top:hover i {
    color: #F7CAC9;
}

.back-to-top i {
    color: #24444f;
    padding-top: 10px;
}


/**********************************/
/********** Top Bar CSS ***********/
/**********************************/
.top-bar {
    position: relative;
    height: 35px;
    background: #24444f;
}

.top-bar .top-bar-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.top-bar .top-bar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.top-bar .text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 35px;
    padding: 0 10px;
    text-align: center;
    border-left: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .text:last-child {
    border-right: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .text i {
    font-size: 13px;
    color: #ffffff;
    margin-right: 5px;
}

.top-bar .text h2 {
    color: #eeeeee;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 1px;
    margin: 0;
}

.top-bar .text p {
    color: #eeeeee;
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 0 5px;
}

.top-bar .social {
    display: flex;
    height: 35px;
    font-size: 0;
    justify-content: flex-end;
}

.top-bar .social a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 100%;
    font-size: 16px;
    color: #ffffff;
    border-right: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .social a:first-child {
    border-left: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .social a:hover {
    color: #092a49;
    background: #ffffff;
}

@media (min-width: 999px) {
    .top-bar {
        padding: 0 60px;
    }
}


/**********************************/
/*********** Nav Bar CSS **********/
/**********************************/
.navbar {
    position: relative;
    transition: .5s;
    z-index: 100;
}

.navbar.nav-sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
}

.navbar .navbar-brand {
    margin: 0;
    color: #ffffff;
    font-size: 45px;
    line-height: 0px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: .5s;

}

.navbar .navbar-brand span {
    color: #dfdde9;
    /*text-transform: lowercase;*/
    transition: .5s;
}

.navbar.nav-sticky .navbar-brand span {
    color: #ffffff;
}

.navbar .navbar-brand img {
    max-width: 100%;
    max-height: 40px;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    padding: 10px 10px 8px 10px;
    margin-left: 0px;
    color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    background: rgba(256, 256, 256, .1);
    transition: none;
}

.navbar .dropdown-menu {
    margin-top: 0;
    border: 0;
    border-radius: 0;
    background: #f8f9fa;
}

@media (min-width: 999px) {
    .navbar {
        position: absolute;
        width: 100%;
        top: 35px;
        /* padding: 20px 60px; */
        background: #fff ;
        z-index: 100;
        height: 100px;
    }

    .navbar.nav-sticky {
        /* padding: 10px 60px; */
        background: #ffffff;
    }

    .navbar a.nav-link {
        padding: 8px 10px;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

@media (max-width: 991.98px) {
    .navbar {
        padding: 15px;
        background: #ffffff;
        height: 100px;
    }

    .navbar .navbar-brand span {
        color: #ffffff;
    }

    .navbar a.nav-link {
        padding: 5px;
    }

    .navbar .dropdown-menu {
        box-shadow: none;
    }
}


/*******************************/
/********** Hero CSS ***********/
/*******************************/
.hero {
    position: relative;
    width: 100%;
    margin-bottom: 45px;
    padding: 90px 0;
    background: #F7CAC9;
    overflow: hidden;
}

.hero .container-fluid {
    padding: 0;
}

.hero .hero-image {
    position: relative;
    text-align: right;
}

.hero .hero-image img {
    max-width: 80%;
    max-height: 80%;
}

.hero .hero-text {
    position: relative;
    padding-left: 75px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.hero .hero-text h1 {
    color: #24444f;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 20px;
}

.hero .hero-text p {
    color: #24444f;
    font-size: 18px;
    margin-bottom: 25px;
}

.hero .hero-btn .btn {
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #F7CAC9;
    background: #24444f;
    border-radius: 0;
    transition: .3s;
}

.hero .hero-btn .btn:hover {
    color: #24444f;
    background: #ffffff;
}

.hero .hero-btn .btn:first-child {
    margin-right: 10px;
}

@media (max-width: 991.98px) {
    .hero .hero-text {
        padding: 0 15px;
    }

    .hero .hero-text h1 {
        font-size: 35px;
    }

    .hero .hero-text p {
        font-size: 16px;
    }

    .hero .hero-text .btn {
        padding: 12px 30px;
        letter-spacing: 1px;
    }
}

@media (max-width: 767.98px) {
    .hero .hero-text h1 {
        font-size: 40px;
        font-weight: 600;
    }

    .hero .hero-text p {
        font-size: 14px;
    }

    .hero .hero-text .btn {
        padding: 10px 15px;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .hero .hero-image {
        text-align: left;
        margin-top: 30px;
    }

    .hero .hero-image img {
        max-width: 100%;
        max-height: 100%;
    }
}

@media (max-width: 575.98px) {
    .hero .hero-text h1 {
        font-size: 25px;
        font-weight: 600;
    }

    .hero .hero-text p {
        font-size: 14px;
    }

    .hero .hero-text .btn {
        padding: 8px 10px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
    }
}


/*******************************/
/******* Page Header CSS *******/
/*******************************/
@media screen and (max-width: 768px){
    .page-headery{
        text-align: center;
        margin-top: -100px;
        /* background-size: contain; */
    }
    

}
.page-headera {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    /* background-image: url(../img/ban21.png); */
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
    /* background-color: #F7CAC9; */
    background-image: url('../img/Bread-crum.jpg');
  margin-top: 70px;
  
}

.page-headera h2 {
    position: relative;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}


.page-headera a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color: #fff;
    text-align: center;
}

.page-headera a:hover {
    color: #ffffff;
}

.page-headera p{
    text-align: justify;
}
.page-headera a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: #24444f;
}
.page-headera img{
    margin-top: 600px;
}
.page-headera a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-headera{
        margin-top: -150px;
    }
    .page-headera h2 {
        font-size: 35px;
        margin-top: 80px;
    }

    .page-headera a {
        font-size: 18px;
    }
    

    .resposnive_breadmcrum{
        margin-top: -15px;
        margin-left: -10px;
    }

}

/* --------------------------------- */
.page-headerc {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    /* background-image: url(../img/mycontact.png); */
    /* background-size: contain; */
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
    /* background-color: #F7CAC9; */
    background-image: url('../img/Bread-crum.jpg');
margin-top: 70px;
    background-size: cover;
}

.page-headerc h2 {
    position: relative;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
   
}

.page-headerc a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color: #ffffff;
}

.page-headerc a:hover {
    color: #ffffff;
}

.page-headerc a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: #ffffff;
}

.page-headerc a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-headerc{
margin-top: -100px;
    }
    .page-headerc h2 {
        font-size: 35px;
        margin-top: 70px;
    }

    .page-headerc a {
        font-size: 18px;
    }
}

/* ------------------- */

.page-headerg {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    /* background-image: url(../img/gallery1.jpeg); */
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
    /* background-color: #F7CAC9; */
    background-image: url('../img/Bread-crum.jpg');
    margin-top: 70px;
}

.page-headerg h2 {
    position: relative;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
}

.page-headerg a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color: #fff;
}

.page-headerg a:hover {
    color: #ffffff;
}

.page-headerg a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: white;
}

.page-headerg a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-headerg{
margin-top: -90px;
    }
    .page-headerg h2 {
        font-size: 35px;
        margin-top: 70px;
        color: #FFFFFF;
    }

    .page-headerg a {
        font-size: 18px;
        color: #FFFFFF;
    }
}

/* ----------------------------- */

.page-headery {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    /* background-image:  url(../img/myyoga.png); */
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
  background-image: url('../img/Bread-crum.jpg');
  margin-top: 70px;
}


.page-headery h2 {
    position: relative;
    color:white; /* #24444f;*/
    font-size: 40px;
    font-weight: 700;
   
}

.page-headery a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color:white; /* #24444f; */
}

.page-headery a:hover {
    color:#F4C2C2 /* #ffffff; */
}
.page-headery img{
    margin-top: 100px;

}

.page-headery a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: #FFFFFF /*#F4C2C2 #24444f; */
}

.page-headery a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-headery{
        margin-top: -80px
    }
    .page-headery h2 {
        font-size: 35px;
        margin-top: 40px;
    }

    .page-headery a {
        font-size: 24px;
    }
   
    
}


.page-headerst {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    background-image: url(../img/Bread-crum.jpg);
    margin-top: 70px;
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
    /* background-color: #F7CAC9; */
}


.page-headerst h2 {
    position: relative;
    color:white; /* #24444f;*/
    font-size: 40px;
    font-weight: 700;

}

.page-headerst a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color:white; /* #24444f; */
}

.page-headerst a:hover {
    color:#F4C2C2 /* #ffffff; */
}

.page-headerst a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: #FFFFFF /*#F4C2C2 #24444f; */
}

.page-headerst a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-headerst{
        margin-top: -100px;
    }
    .page-headerst h2 {
        font-size: 35px;
        margin-top: 40px;
    }

    .page-headerst a {
        font-size: 20px;
    }
}




















.page-headercu {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    background-image: url(../img//Bread-crum.jpg);
    margin-top: 70px;
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    height: 360px;
    /* background-color: #F7CAC9; */
}


.page-headercu h2 {
    position: relative;
    color:white; /* #24444f;*/
    font-size: 40px;
    font-weight: 700;
    margin-top: 40px;
}

.page-headercu a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color:white; /* #24444f; */
}

.page-headercu a:hover {
    color:pink /* #ffffff; */
}

.page-headercu a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: white /*#F4C2C2 #24444f; */
}

.page-headercu a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-headercu{
        margin-top: -100px
    }
    .page-headercu h2 {
        font-size: 35px;
    }

    .page-headercu a {
        font-size: 20px;
    }
    .resposnive_breadmcrum{
        margin-top: -15px;
        margin-left: -10px;
    }
    .yinyanglogo{
        margin-left: -10px;
    }
}




.page-headerh {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    background-image: url(../img/Bread-crum.jpg);
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
    /* background-color: #F7CAC9; */
    margin-top:70px ;
}


.page-headerh h2 {
    position: relative;
    color:white; /* #24444f;*/
    font-size: 40px;
    font-weight: 700;
   
}

.page-headerh a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color:white; /* #24444f; */
}

.page-headerh a:hover {
    color:#F4C2C2 /* #ffffff; */
}

.page-headerh a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: #FFFFFF /*#F4C2C2 #24444f; */
}

.page-headerh a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-headerh{
        margin-top: -100px;
    }
    .page-headerh h2 {
        font-size: 35px;
        margin-top: 40px;
    }

    .page-headerh a {
        font-size: 20px;
    }
    .resposnive_breadmcrum{
        margin-top: -15px;
        margin-left: -10px;
    }
}




.page-headervideo {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    background-image: url(../img/Bread-crum.jpg);
    margin-top: 70px;
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
    /* background-color: #F7CAC9; */
    
}
.page-headervideo h2 {
    position: relative;
    color:white; /* #24444f;*/
    font-size: 40px;
    font-weight: 700;
 
}

.page-headervideo a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color:white; /* #24444f; */
}

.page-headervideo a:hover {
    color:#F4C2C2 /* #ffffff; */
}

@media (max-width: 767.98px) {
    .page-headervideo{
        margin-top: -100px;
        background-image: url(../img/Bread-crum.jpg);
    }
    .page-headervideo h2 {
        /* font-size: 35px; */
        margin-top: 40px;
    }

    .page-headervideo a {
        font-size: 18px;
    }
  
}

.page-headerac {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    background-image: url(../img/Bread-crum.jpg);
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
    /* background-color: #F7CAC9; */
    margin-top: 70px;
}


.page-headerac h2 {
    position: relative;
    color:white; /* #24444f;*/
    font-size: 40px;
    font-weight: 700;
 
}

.page-headerac a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color:white; /* #24444f; */
}

.page-headerac a:hover {
    color:#F4C2C2 /* #ffffff; */
}

.page-headerac a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: #FFFFFF /*#F4C2C2 #24444f; */
}
.page-headerac .yinyanglogo{
    height:200px;
     width:200px; 
     margin-top:-130px;
     margin-left:1230px
    }
.page-headerac a:last-child::after {
    display: none;
}

@media (max-width: 400px) {
    .page-headerac{
        margin-top: -100px;
        background-image: url(../img/Bread-crum.jpg);
    }
    .page-headerac h2 {
        /* font-size: 35px; */
        margin-top: 40px;
    }

    .page-headerac a {
        font-size: 18px;
    }
    .page-headerac .yinyanglogo{
        width: 130px;
        height: 100px;
        margin-left: 280px;
        margin-top: -160px;
        z-index: 50;
        
    }
  
}


.page-headercou {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    background-image: url(../img/Bread-crum.jpg);
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
    /* background-color: #F7CAC9; */
    margin-top: 70px;
}
@media (max-width: 767px) {
    /* Adjust styles for screens with a maximum width of 767px (typical mobile devices) */
   
}

.page-headercou h2 {
    position: relative;
    color:white; /* #24444f;*/
    font-size: 40px;
    font-weight: 700;
  
}

.page-headercou a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color:white; /* #24444f; */
}

.page-headercou a:hover {
    color:#F4C2C2 /* #ffffff; */
}

.page-headercou a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: #FFFFFF /*#F4C2C2 #24444f; */
}

.page-headercou a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-headercou{
        margin-top: -100px;
        background-image: url(../img/Bread-crum.jpg);
    }
    .page-headercou h2 {
        font-size: 35px;
        margin-top: 40px;
    }

    .page-headercou a {
        font-size: 20px;
    }
}
































.page-headern {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    background-image: url(../img/Bread-crum.jpg);

    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
    /* background-color: #F7CAC9; */
}


.page-headern h2 {
    position: relative;
    color:rgb(255, 255, 255); /* #24444f;*/
    font-size: 40px;
    font-weight: 700;
    text-align: center;

}

.page-headern a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color:white; /* #24444f; */
    text-align: center;
}

.page-headern a:hover {
    color:#F4C2C2 /* #ffffff; */
}

.page-headern a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: #FFFFFF /*#F4C2C2 #24444f; */
}

.page-headern a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-headern{
        margin-top: -100px;
        background-image: url(../img/Bread-crum.jpg);
       
    }
    .page-headern h2 {
        font-size: 35px;
        margin-top: 40px;
    }

    .page-headern a {
        font-size: 20px;
        text-align: center;

    }
}







.page-headers {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    background-image: url(../img/Bread-crum.jpg);
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
    /* background-color: #F7CAC9; */
    margin-top: 70px;

}

@media (max-width: 767px) {
    /* Adjust styles for screens with a maximum width of 767px (typical mobile devices) */
    .page-headers {
       background-image: url(../img/Bread-crum.jpg); 
        /* height: 350px;  */
        /* Adjust the height for mobile view */
    }
    .page-headers{
        margin-top: -100px;
    }
  
}


.page-headers h2 {
    position: relative;
    color:white; /* #24444f;*/
    font-size: 40px;
    font-weight: 700;
   
}

.page-headers a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color:white; /* #24444f; */
}

.page-headers a:hover {
    color:#F4C2C2 /* #ffffff; */
}

.page-headers a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: #FFFFFF /*#F4C2C2 #24444f; */
}

.page-headers a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-headers h2 {
        font-size: 35px;
        margin-top: 40px;
    }

    .page-headers a {
        font-size: 20px;
        text-align: center;
    }
}


.page-headerco {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    background-image: url(../img/Bread-crum.jpg);
 
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: right;
    background-repeat: no-repeat;
    /* This will center the image within the container */
    height: 360px;
    /* background-color: #F7CAC9; */

}


.page-headerco h2 {
    position: relative;
    color:white; /* #24444f;*/
    font-size: 40px;
    font-weight: 700;

}

.page-headerco a {
    position: relative;
    /* padding: 0 12px; */
    font-size:24px;
    color:white; /* #24444f; */
}

.page-headerco a:hover {
    color:#F4C2C2 /* #ffffff; */
}

.page-headerco a::after {
    position: absolute;
    /* content: "/"; */
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: #FFFFFF /*#F4C2C2 #24444f; */
}

.page-headerco a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-haederco{
        margin-top: 100px;
        background-image: url(../img/Bread-crum.jpg);
        /* height: 100px; */
    }
    .page-headerco h2 {
        font-size: 35px;
        margin-top: 40px;
    }

    .page-headerco a {
        font-size: 20px;
        text-align: center;
    }
}

/* ------------------------------- */

/*******************************/
/******* Section Header ********/
/*******************************/
.section-header {
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 45px;
}

.section-header p {
    display: inline-block;
    margin: 0 30px;
    margin-bottom: 5px;
    padding: 0 10px;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    background: #ffffff;
    text-align: justify;
    text-justify: auto;
    color: black;
}

.section-header p::after {
    position: absolute;
    content: "";
    height: 2px;
    top: 11px;
    right: -30px;
    left: -30px;
    background: #F7CAC9;
    z-index: -1;
}

.section-header h2 {
    margin: 0;
    position: relative;
    font-size: 40px;
    font-weight: 700;
}

@media (max-width: 767.98px) {
    .section-header h2 {
        font-size: 28px;
    }
}


/*******************************/
/********** About CSS **********/
/*******************************/
.about {
    position: relative;
    width: 100%;
    padding: 45px 0;
}

.about .section-header {
    margin-bottom: 30px;
    margin-left: 0;
}

.about .about-img {
    position: relative;
    height: 10%;
}

.about .about-img img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about .about-text p {
    font-size: 16px;
}

.about .about-text a.btn {
    position: relative;
    margin-top: 15px;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #F7CAC9;
    background: #24444f;
    border-radius: 0;
    transition: .3s;
}

.about .about-text a.btn:hover {
    color: #24444f;
    background: #24444f;
}

@media (max-width: 767.98px) {
    .about .about-img img {
        margin-top: 50px;
        height: auto;
    }
}



/*******************************/
/********* Service CSS *********/
/*******************************/
.service {
    position: relative;
    width: 100%;
    padding: 45px 0 15px 0;
}

.service .service-item {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    padding: 30px;
    border: 1px solid rgba(0, 0, 0, .07);
    transition: .3s;
}

.service .service-item:hover,
.service .service-item.active {
    background: #24444f;
    color: #ffffff;
}

.service .service-icon {
    position: relative;
    margin: 0 auto 25px auto;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.service .service-icon i {
    position: relative;
    font-size: 50px;
    color: #24444f;
    z-index: 3;
}

.service .service-icon:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #F7CAC9;
    border-radius: 50%;
    animation: pulse-border 2000ms ease-out infinite;
}

.service .service-icon:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #F7CAC9;
    border-radius: 50%;
    transition: all 200ms;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
        opacity: 0;
    }
}

.service .service-item h3 {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    transition: .3s;
}

.service .service-item:hover h3,
.service .service-item.active h3 {
    color: #F7CAC9;
}

.service .service-item p {
    margin: 0;
    font-size: 16px;
}



/*******************************/
/********** Class CSS **********/
/*******************************/
.class {
    position: relative;
    padding: 0 0 15px 0;
}

.class #class-filter {
    padding: 0;
    margin: -15px 0 25px 0;
    list-style: none;
    font-size: 0;
    text-align: center;
}

.class #class-filter li {
    cursor: pointer;
    display: inline-block;
    margin: 5px;
    padding: 6px 12px;
    color: #24444f;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0;
    background: none;
    border: 1px solid #24444f;
    transition: .3s;
}

.class #class-filter li:hover,
.class #class-filter li.filter-active {
    background: #24444f;
    color:  #9e9595;
}

.class .class-item {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    transition: .3s;
}

.class .class-wrap {
    position: relative;
    width: 100%;
}

.class .class-img {
    position: relative;
}

.class .class-img img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.class .class-text {
    position: relative;
    padding: 30px 30px 25px 30px;
    border-right: 1px solid rgba(0, 0, 0, .07);
    border-bottom: 1px solid rgba(0, 0, 0, .07);
    border-left: 1px solid rgba(0, 0, 0, .07);
}

.class .class-text h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.class .class-teacher {
    position: relative;
    height: 40px;
    margin-top: -50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    border-radius: 40px;
}

.class .class-teacher img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #F7CAC9;
}

.class .class-teacher h3 {
    width: calc(100% );
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 0 15px;
    white-space: nowrap;
    overflow: hidden;
}

.class .class-teacher a {
    width: 40px;
    height: 40px;
    padding: 0 0 3px 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 100;
    color: #24444f;
    background: #F7CAC9;
    border-radius: 40px;
    transition: .3s;
}

.class .class-wrap:hover .class-teacher a {
    color: #F7CAC9;
    background: #24444f;
}

.class .class-meta {
    position: relative;
    display: flex;
    align-items: center;
}

.class .class-meta p {
    margin: 0;
    font-size: 14px;
}

.class .class-meta p i {
    margin-right: 5px;
    color: #24444f;
}

.class .class-meta p:first-child {
    margin-right: 10px;
}



/*******************************/
/********* Pricing CSS *********/
/*******************************/

/* .review {
    position: relative;
    height: 100vh;
    width: 200%;
    margin: 45px 0;
    padding: 90px 0;
    background: linear-gradient(rgba(245, 242, 242, 0.1), rgba(246, 245, 245, 0.1)), url(../img/banner.jpg);
   
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

.discount {
    position: relative;
    text-align: center;
    width: 100%;
    margin: 45px 0;
    padding: 90px 0;
    background: linear-gradient(rgba(247, 202, 201, .7), rgba(247, 202, 201, .7)), url(../img/discount-bg.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.discount .container {
    max-width: 750px;
    text-align: center;
}



.discount .section-header {
    margin-bottom: 20px;
}

.discount .section-header p {
    color: #24444f;
    background: transparent;
}

.discount .section-header p::after {
    display: none;
}

.discount .section-header h2 span {
    color: #F32B56;
    font-size: 50px;
    text-align: center;
}

.discount .discount-text p {
    color: #454545;
}

.discount .discount-text .btn {
    margin-top: 10px;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #F7CAC9;
    background: #24444f;
    border-radius: 0;
    transition: .3s;
}

.discount .discount-text .btn:hover {
    color: #24444f;
    background: #ffffff;
}


/*******************************/
/********* Pricing CSS *********/
/*******************************/
.price {
    position: relative;
    width: 100%;
    padding: 45px 0 15px 0;
}

.price .price-item {
    position: relative;
    margin-bottom: 30px;
}

.price .price-header,
.price .price-body,
.price .price-footer {
    position: relative;
    width: 100%;
    text-align: center;
    background: #ffffff;
    overflow: hidden;
}

.price .price-header {
    padding: 30px;
    background: #F7CAC9;
    color: #24444f;
}

.price .price-item.featured-item .price-header {
    background: #24444f;
    color: #F7CAC9;
}

.price .price-status {
    margin-top: -30px;
    text-align: right;
}

.price .price-status span {
    position: relative;
    display: inline-block;
    height: 30px;
    right: -30px;
    padding: 7px 10px;
    text-align: center;
    color: #24444f;
    font-size: 14px;
    line-height: 14px;
    background: #F7CAC9;
}

.price .price-title h2 {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
}

.price .price-prices h2 {
    font-size: 45px;
    font-weight: 700;
    margin-left: 10px;
}

.price .price-prices h2 small {
    position: absolute;
    font-size: 18px;
    font-weight: 400;
    margin-top: 9px;
    margin-left: -12px;
}

.price .price-prices h2 span {
    margin-left: 1px;
    font-size: 18px;
    font-weight: 400;
}

.price .price-item.featured-item h2 {
    color: #F7CAC9;
}

.price .price-body {
    padding: 25px 0 20px 0;
    border: 1px solid rgba(0, 0, 0, .07);
    border-bottom: none;
}

.price .price-description ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.price .price-description ul li {
    padding: 7px;
    border-bottom: 1px solid rgba(0, 0, 0, .07);
}

.price .price-description ul li:last-child {
    border: none;
}

.price .price-action {
    border: 1px solid rgba(0, 0, 0, .07);
    border-top: none;
}

.price .price-action a.btn {
    margin-bottom: 30px;
    padding: 12px 20px;
    color: #24444f;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 0;
    background: #F7CAC9;
    transition: .3s;
}

.price .price-action a:hover,
.price .price-item.featured-item .price-action a {
    color: #F7CAC9;
    background: #24444f;
}

.price .price-item.featured-item .price-action a:hover {
    color: #24444f;
    background: #F7CAC9;
}


/*******************************/
/*********** Team CSS **********/
/*******************************/
.team {
    position: relative;
    width: 100%;
  
    padding: 45px 0 15px 0;
}

.team .team-item {
    position: relative;
    margin-bottom: 30px;
    padding: 15px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, .07);
}

.team .team-img {
    position: relative;
    overflow: hidden;
}

.team .team-img img {
    position: relative;
    width: 100%;
}

.team .team-social {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
}

.team .team-social a {
    position: relative;
    margin: 0 3px;
    margin-top: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    font-size: 16px;
    color: #F7CAC9;
    background: #24444f;
    opacity: 0;
}

.team .team-social a:hover {
    color: #24444f;
    background: #F7CAC9;
}


.team .team-item:hover .team-social {
    background: rgba(256, 256, 256, .5);
}

.team .team-item:hover .team-social a:first-child {
    opacity: 1;
    margin-top: 0;
    transition: .3s 0s;
}

.team .team-item:hover .team-social a:nth-child(2) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .1s;
}

.team .team-item:hover .team-social a:nth-child(3) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .2s;
}

.team .team-item:hover .team-social a:nth-child(4) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .3s;
}

.team .team-text {
    position: relative;
    padding: 25px 15px 10px 15px;
    text-align: center;
    background: #ffffff;
}

.team .team-text h2 {
    font-size: 18px;
    font-weight: 600;
}

.team .team-text p {
    margin: 0;
}


/*******************************/
/******* Testimonial CSS *******/
/*******************************/
.testimonial {
    position: relative;
    padding: 45px 0;
}

.testimonial .container {
    padding: 0;
}

.testimonial .testimonial-item {
    position: relative;
    margin: 0 15px;
    text-align: center;
}

.testimonial .testimonial-img {
    position: relative;
    margin-bottom: 15px;
    z-index: 1;
}

.testimonial .testimonial-item img {
    margin: 0 auto;
    width: 120px;
    padding: 13px;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, .07);
    background: #ffffff;
    transition: .5s;
}

.testimonial .testimonial-text {
    position: relative;
    margin-top: -70px;
    padding: 75px 25px 25px 25px;
    text-align: center;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, .07);
}

.testimonial .testimonial-item p {
    font-size: 16px;
    color: #666666;
}

.testimonial .testimonial-text h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.testimonial .testimonial-text h4 {
    color: #666666;
    font-size: 14px;
    margin-bottom: 0;
}

.testimonial .owl-item.center img {
    border-color: transparent;
}

.testimonial .owl-item.center .testimonial-text {
    background: #24444f;
}

.testimonial .owl-item.center .testimonial-text h3 {
    color: #F7CAC9;
}

.testimonial .owl-item.center .testimonial-text p,
.testimonial .owl-item.center .testimonial-text h4 {
    color: #ffffff;
}

.testimonial .owl-dots {
    margin-top: 15px;
    text-align: center;
}

.testimonial .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #F7CAC9;
}

.testimonial .owl-dot.active {
    background: #24444f;
}


/*******************************/
/*********** Blog CSS **********/
/*******************************/
.blog {
    position: relative;
    width: 100%;
    padding: 5px 0;
}

.blog .blog-carousel {
    position: relative;
    width: calc(100% + 30px);
    left: -15px;
    right: -15px;
}

.blog .blog-item {
    position: relative;
    margin: 0 15px;
}

.blog .blog-page .blog-item {
    margin: 0 0 30px 0;
}

.blog .blog-img {
    position: relative;
    width: 100%;
}

.blog .blog-img img {
    width: 100%;
}

.blog .blog-text {
    position: relative;
    padding: 25px 30px;
    border-right: 1px solid rgba(0, 0, 0, .07);
    border-bottom: 1px solid rgba(0, 0, 0, .07);
    border-left: 1px solid rgba(0, 0, 0, .07);
}

.blog .blog-text h2 {
    font-size:24px;
    font-weight: 600;
}

.blog .blog-text p {
    margin-bottom: 10px;
}

.blog .blog-item a.btn {
    padding: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #24444f;
    transition: .3s;
}

.blog .blog-item a.btn i {
    margin-left: 5px;
}

.blog .blog-item a.btn:hover {
    color: #F7CAC9;
}

.blog .blog-meta {
    position: relative;
    display: flex;
    margin-bottom: 15px;
}

.blog .blog-meta p {
    margin: 0 10px 0 0;
    font-size: 13px;
}

.blog .blog-meta i {
    margin-right: 5px;
}

.blog .blog-meta p:last-child {
    margin: 0;
}

.blog .owl-nav {
    width: 150px;
    margin: 32px auto 0 auto;
    display: flex;
}

.blog .owl-nav .owl-prev,
.blog .owl-nav .owl-next {
    margin-left: 15px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F7CAC9;
    background: #24444f;
    font-size: 22px;
    transition: .3s;
}

.blog .owl-nav .owl-prev:hover,
.blog .owl-nav .owl-next:hover {
    color: #24444f;
    background: #F7CAC9;
}

.blog .pagination .page-link {
    color: #24444f;
    border-radius: 0;
    border-color: #24444f;
}

.blog .pagination .page-link:hover,
.blog .pagination .page-item.active .page-link {
    color: #F7CAC9;
    background: #24444f;
}

.blog .pagination .disabled .page-link {
    color: #999999;
}


/*******************************/
/******** Portfolio CSS ********/
/*******************************/
.portfolio {
    position: relative;
    padding: 45px 0 15px 0;
}

.portfolio #portfolio-filter {
    padding: 0;
    margin: -15px 0 25px 0;
    list-style: none;
    font-size: 0;
    text-align: center;
}

.portfolio #portfolio-filter li {
    cursor: pointer;
    display: inline-block;
    margin: 5px;
    padding: 6px 12px;
    color: #24444f;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0;
    background: none;
    border: 1px solid #24444f;
    transition: .3s;
}

.portfolio #portfolio-filter li:hover,
.portfolio #portfolio-filter li.filter-active {
    background: #24444f; 
    color: #F7CAC9 !important;
}

.portfolio .portfolio-item {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    transition: .3s;
}

.portfolio .portfolio-wrap {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.portfolio img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s;
}

.portfolio .portfolio-wrap:hover img {
    transform: rotate(5deg) scale(1.12);
}


/*******************************/
/********* Contact CSS *********/
/*******************************/
.contact {
    position: relative;
    width: 100%;
    padding: 45px 0;
    margin-top:-50px
}

.contact .container {
    max-width: 900px;
}

.contact .contact-item {
    text-align: center;
    margin-bottom: 30px;
}

.contact .contact-item i {
    width: 50px;
    height: 50px;
    padding: 12px 0;
    font-size: 25px;
    color: #24444f;
    margin-bottom: 15px;
    border: 1px solid #F7CAC9;
    border-radius: 50px;
}

.contact .contact-item h2 {
    font-size: 25px;
    font-weight: 700;
}

.contact .contact-form {
    position: relative;
}

.contact .contact-form input {
    height: 45px;
    padding: 15px;
    background: none;
    border-radius: 0;
}

.contact .contact-form textarea {
    height: 150px;
    padding: 15px;
    background: none;
    border-radius: 0;
}

.contact .contact-form .btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #F7CAC9;
    background: #24444f;
    border-radius: 0;
    transition: .3s;
}

.contact .contact-form .btn:hover {
    color: #24444f;
    background: #F7CAC9;
}

.contact .help-block ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}



/*******************************/
/******* Single Post CSS *******/
/*******************************/
.single {
    position: relative;
    padding: 45px 0;
}

.single .single-content {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}

.single .single-content img {
    margin-bottom: 20px;
    width: 100%;
}
.single .single-content p {
    text-align: justify;
    text-justify: auto;
    color: black;
}
.single .single-tags {
    margin: -5px -5px 41px -5px;
    font-size: 0;
}

.single .single-tags a {
    margin: 5px;
    display: inline-block;
    padding: 7px 15px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    border: 1px solid #dddddd;
}

.single .single-tags a:hover {
    color: #F7CAC9;
    background: #24444f;
}

.single .single-bio {
    margin-bottom: 45px;
    padding: 30px;
    background: #f3f6ff;
    display: flex;
}

.single .single-bio-img {
    width: 100%;
    max-width: 100px;
}

.single .single-bio-img img {
    width: 100%;
}

.single .single-bio-text {
    padding-left: 30px;
}

.single .single-bio-text h3 {
    font-size: 20px;
    font-weight: 700;
}

.single .single-bio-text p {
    margin: 0;
}

.single .single-related {
    margin-bottom: 45px;
}

.single .single-related h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 25px;
}

.single .related-slider {
    position: relative;
    margin: 0 -15px;
    width: calc(100% + 30px);
}

.single .related-slider .post-item {
    margin: 0 15px;
}

.single .post-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.single .post-item .post-img {
    width: 100%;
    max-width: 80px;
}

.single .post-item .post-img img {
    width: 100%;
}

.single .post-item .post-text {
    padding-left: 15px;
}

.single .post-item .post-text a {
    font-size: 16px;
    font-weight: 400;
}

.single .post-item .post-meta {
    display: flex;
    margin-top: 8px;
}

.single .post-item .post-meta p {
    display: inline-block;
    margin: 0;
    padding: 0 3px;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
}

.single .post-item .post-meta p a {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
}

.single .related-slider .owl-nav {
    position: absolute;
    width: 90px;
    top: -55px;
    right: 15px;
    display: flex;
}

.single .related-slider .owl-nav .owl-prev,
.single .related-slider .owl-nav .owl-next {
    margin-left: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #24444f;
    background: #F7CAC9;
    font-size: 16px;
    transition: .3s;
}

.single .related-slider .owl-nav .owl-prev:hover,
.single .related-slider .owl-nav .owl-next:hover {
    color: #F7CAC9;
    background: #24444f;
}

.single .single-comment {
    position: relative;
    margin-bottom: 45px;
}

.single .single-comment h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 25px;
}

.single .comment-list {
    list-style: none;
    padding: 0;
}

.single .comment-child {
    list-style: none;
}

.single .comment-body {
    display: flex;
    margin-bottom: 30px;
}

.single .comment-img {
    width: 60px;
}

.single .comment-img img {
    width: 100%;
}

.single .comment-text {
    padding-left: 15px;
    width: calc(100% - 60px);
}

.single .comment-text h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 3px;
}

.single .comment-text span {
    display: block;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px;
}

.single .comment-text .btn {
    padding: 3px 10px;
    font-size: 14px;
    color: #24444f;
    background: #dddddd;
    border-radius: 0;
}

.single .comment-text .btn:hover {
    background: #F7CAC9;
}

.single .comment-form {
    position: relative;
}

.single .comment-form h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 25px;
}

.single .comment-form form {
    padding: 30px;
    background: #f3f6ff;
}

.single .comment-form form .form-group:last-child {
    margin: 0;
}

.single .comment-form input,
.single .comment-form textarea {
    border-radius: 0;
}

.single .comment-form .btn {
    padding: 15px 30px;
    color: #F7CAC9;
    background: #24444f;
}

.single .comment-form .btn:hover {
    color: #24444f;
    background: #F7CAC9;
}


/**********************************/
/*********** Sidebar CSS **********/
/**********************************/
.sidebar {
    position: relative;
    width: 100%;
}

@media(max-width: 991.98px) {
    .sidebar {
        margin-top: 45px;
    }
}

.sidebar .sidebar-widget {
    position: relative;
    margin-bottom: 45px;
}

.sidebar .sidebar-widget .widget-title {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 5px;
    font-size: 40px;
    font-weight: 700;
}

.sidebar .sidebar-widget .widget-title::after {
    position: absolute;
    content: "";
    width: 60px;
    height: 2px;
    bottom: 0;
    left: 0;
    background: #F7CAC9;
}

.sidebar .sidebar-widget .search-widget {
    position: relative;
}

.sidebar .search-widget input {
    height: 50px;
    border: 1px solid #dddddd;
    border-radius: 0;
}

.sidebar .search-widget input:focus {
    box-shadow: none;
}

.sidebar .search-widget .btn {
    position: absolute;
    top: 6px;
    right: 15px;
    height: 40px;
    padding: 0;
    font-size: 25px;
    color: #F7CAC9;
    background: none;
    border-radius: 0;
    border: none;
    transition: .3s;
}

.sidebar .search-widget .btn:hover {
    color: #24444f;
}

.sidebar .sidebar-widget .recent-post {
    position: relative;
}

.sidebar .sidebar-widget .tab-post {
    position: relative;
}

.sidebar .tab-post .nav.nav-pills .nav-link {
    color: #F7CAC9;
    background: #24444f;
    border-radius: 0;
}

.sidebar .tab-post .nav.nav-pills .nav-link:hover,
.sidebar .tab-post .nav.nav-pills .nav-link.active {
    color: #24444f;
    background: #F7CAC9;
}

.sidebar .tab-post .tab-content {
    padding: 15px 0 0 0;
    background: transparent;
}

.sidebar .tab-post .tab-content .container {
    padding: 0;
}

.sidebar .sidebar-widget .category-widget {
    position: relative;
}

.sidebar .category-widget ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar .category-widget ul li {
    margin: 0 0 12px 22px;
}

.sidebar .category-widget ul li:last-child {
    margin-bottom: 0;
}

.sidebar .category-widget ul li a {
    display: inline-block;
    line-height: 23px;
}

.sidebar .category-widget ul li::before {
    position: absolute;
    content: '\f105';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #F7CAC9;
    left: 1px;
}

.sidebar .category-widget ul li span {
    display: inline-block;
    float: right;
}

.sidebar .sidebar-widget .tag-widget {
    position: relative;
    margin: -5px -5px;
}

.single .tag-widget a {
    margin: 5px;
    display: inline-block;
    padding: 7px 15px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    border: 1px solid #dddddd;
}

.single .tag-widget a:hover {
    color: #F7CAC9;
    background: #24444f;
}

.sidebar .image-widget {
    display: block;
    width: 100%;
    overflow: hidden;
}

.sidebar .image-widget img {
    max-width: 100%;
    transition: .3s;
}

.sidebar .image-widget img:hover {
    transform: scale(1.1);
}



/*******************************/
/********* Footer CSS **********/
/*******************************/
.footer {
    position: relative;
    margin-top: 45px;
    background: #000000;
}

.footer .container-fluid {
    padding: 90px 0 0 0;
    background: linear-gradient(rgb(36,68,79,.9), rgba(36,68,79,.9)), url(../img/footer-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -60px;
}

.footer .footer-info {
    position: relative;
    width: 100%;
    text-align: center;
}

.footer .footer-info .footer-logo {
    display: inline-block;
    color: #F7CAC9;
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    /* margin-bottom: 45px; */
}

.footer .footer-info .footer-logo span {
    color: #ffffff;
    text-transform: lowercase;
}

.footer .footer-info h3 {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
}

.footer .footer-menu {
    width: 100%;
    display: flex;
    justify-content: center;
}

.footer .footer-menu p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    padding: 0 10px;
    border-right: 1px solid #ffffff;
}

.footer .footer-menu p:last-child {
    border: none;
}

.footer .footer-social {
    position: relative;
    margin-top: 5px;
}

.footer .footer-social a {
    display: inline-block;
}

.footer .footer-social a i {
    margin-right: 15px;
    font-size: 20px;
    color: #ffffff;
    transition: .3s;
}

.footer .footer-social a:last-child i {
    margin: 0;
}

.footer .footer-social a:hover i {
    color: #F7CAC9;
}

.footer .copyright {
    margin-top: 60px;
    padding: 0 30px;
}

.footer .copyright .row {
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright p {
    margin: 0;
    color: #ffffff;
}

.footer .copyright .col-md-6:last-child p {
    text-align: right;
}

.footer .copyright p a {
    color: #ffffff;
    font-weight: 600;
}

.footer .copyright p a:hover {
    color: #F7CAC9;
}

@media (max-width: 767.98px) {

    .footer .copyright p,
    .footer .copyright .col-md-6:last-child p {
        margin: 5px 0;
        text-align: center;
    }
    .team{
        margin-top: 22%;
    }
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
}

.zoom-image {
    transition: transform 0.3s ease-in-out;
}

.zoom-image:hover {
    transform: scale(1.4);
    /* Zoom in by 10% */
}

.rotate-image {
    transition: transform 0.3s ease-in-out;
}

.rotate-image:hover {
    transform: rotate(360deg);
    /* Rotate 360 degrees on hover (full rotation) */
}
/* 
.move-image {
    transition: transform 0.5s ease-in-out;
}

.move-image:hover {
    transform: translateX(50px);
   
} */

.slide-in-image {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

.slide-in-image:hover {
    transform: translateX(0);
}

.fade-in-image {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.fade-in-image:hover {
    opacity: 1;
}

.service-item img {
    position: relative;
    margin-top: 40px;
    transition: .5s;
}

.service-item .service-text {
    position: absolute;
    width: 100%;
    height: calc(100% + 86px);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: rgba(33, 30, 28, 0.5);
    transition: .5s;
    z-index: 1;
}

.service-item:hover img {
    margin-top: 0px;
}

.service-item:hover .service-text {
    height: calc(100% + 40px);
}

.bg-appointment {
    background: linear-gradient(rgba(33, 30, 28, 0.7), rgba(33, 40, 28, 0.7)), url(../img/aa3.jpg), no-repeat center center;
    background-size: cover;
    /* This will make the image cover the entire container */
    background-position: center;
    /* This will center the image within the container */
    height: 750px;
}

.bg-pricing {
    background: linear-gradient(rgba(33, 30, 28, 0.7), rgba(33, 40, 28, 0.7)), url(), no-repeat center center;
    background-size: cover;
}

.bg-jumbotron {
    background: linear-gradient(rgba(33, 30, 28, 0.7), rgba(33, 40, 28, 0.7)), url(), no-repeat center center;
    background-size: cover;
}

@media (max-width: 767.98px) {

    .hours-text,
    .pricing-text,
    .contact-form .bg-appointment .bg-pricing .bg-jumbotron .pl-5,
    .px-5 {
        margin-left: -120px;
    }
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: white;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(249, 163, 146, 0.25);
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
    appearance: none;
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #757575;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

select.form-control[size],
select.form-control[multiple] {
    height: auto;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
    color: #6c757d;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

@media (max-width: 767px) {
    .service-item img {
        margin-top: 20px;
        /* Adjust for smaller screens */
    }

    .service-item .service-text {
        height: calc(100% + 20px);
        /* Adjust for smaller screens */
    }

    .hours-text,
    .pricing-text,
    .contact-form {
        margin-left: 0;
        /* Remove negative margin for smaller screens */
    }
}
.animated-sentences-container {
    overflow: hidden;
  }
  
  .animated-sentence {
    opacity: 0;
    transform: translateX(-20px);
    animation: fadeInAndSlide 1.5s forwards;
    margin-bottom: 10px;
  }
  
  @keyframes fadeInAndSlide {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media (max-width: 768px) {
    .zoom_container,
    .image {
      max-width: 100%; /* Ensure responsive width */
      height: 500px; /* Allow height to adjust proportionally */
    }
  
    .zoom_container {
      text-align: left; /* Align text to the left on small screens */
    }
  
    .bg_heading {
      font-size: 14px; /* Further reduce font size for better readability on small screens */
    }
  
    .service {
      margin-top: 5px; /* Reduce top margin for better spacing on small screens */
    }
  }

.nav1{
    padding-top: 25px;
    margin-right: 20px;
}


.nav2{
    padding-top: 25px;
}

.row1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
} 