
.mySlides { display: none }
img { vertical-align: middle; }

.slideshow-container1 {
  max-width: 100vw;
  height: 70vh;
  position: relative;
  margin: auto;
}

.prev1, .next1 {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next1 {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev1:hover, .next1:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

/* .text {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  padding: 8px 12px;
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translate(0, -50%);
  text-align: left;
} */

.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.dot1 {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot1:hover {
  background-color: #ffffff;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from { opacity: .4 }
  to { opacity: 1 }
}

@media only screen and (max-width: 300px) {
  .prev, .next, .text { font-size: 11px }
}

.image-container1 {
  position: relative;
}

.image-container1 img {
  display: block;
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  /* .text {
    position: static;
    transform: none;
    text-align: center;
    margin-top: -250px;
  } */
}

.mySlides.show {
  display: block;
}