.review-slider-container {
  max-width: 700px;
  margin: 0 auto;
  height: 500px; 

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 5px 5px;
    height: 200px 
  }
 
  
}
.review{
  background: linear-gradient(rgba(245, 242, 242, 0.1), rgba(246, 245, 245, 0.1)), url(../img/banner.jpg);
 
  background-size: cover; /* This property ensures that the background image covers the entire container */
  background-position: center center; /* This property centers the background image */
  height: 650px; /* Set the desired height */
  width: 100%;

  @media (max-width: 768px) {
    background-size:1450px;
    background-position: top center; /* Adjust as needed */
    height: 700px; /* Adjust the height for mobile devices */
  }
}

.review-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  
  
}


.review-slide img {
  width: 100%; 

  border-radius: 50% ;
  margin-bottom: 50px;
  object-fit: contain;
  
  max-height: 100px;
  
  
} 

.review-content {
  max-width: 80%;
  margin: 0 auto;
}

.review-content h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.review-content p {
  font-size: 1rem;
  color: #555;
}
/* .review {
  position: relative;
  height: 100vh;
  width: 200%;
  margin: 45px 0;
  padding: 90px 0;
 
 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

@media (max-width: 767px) {
  .review-slide img {
      max-height: 80px; /* Adjusted max-height for smaller screens */
      margin-bottom: 10px; /* Adjusted margin for better spacing on mobile */
  }
}