.slider-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .slider {
    display: flex;
    justify-content: space-between;
    height: 60vh;
  }
  
  .left {
    flex: 2 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 90px;
  }
  
  .right {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  
  .bg-image {
    width: 100%; /* Set a width */
    height: 100%; /* Set a height */
    background-size: cover; /* Ensure the image covers the entire div */
    background-position: center; /* Center the image */
    margin: 10px; /* Add margin between images */
  }
  
  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    border-radius: 2.222em 4px;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  /* Media queries for mobile devices */
  @media (max-width: 768px) {
    .slider {
      flex-direction: column;
      height: 600px;
    }
  
    .left {
      padding: 20px;
      text-align: center;
      margin-top: -30px;
    }
  
    .right {
      flex: 1;
      max-height: 300px; /* Adjust the height as needed */
      overflow: hidden;
      margin-top: -30px;
    }
  
    .bg-image {
      margin: 0;
    }
  
    .prev, .next {
      top: auto;
      bottom: 10px;
      transform: translateX(-50%);
      
    }
  
    .prev {
      left: 25%;
    }
  
    .next {
      right: 25%;
    }
  }