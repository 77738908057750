/* src/AlternatingLayout.css */

/* Container for the alternating layout */
.alt-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  /* Individual row */
  .alt-row {
    display: flex;
    align-items: center;
    width: 100%;
    /* min-height: 100vh;  */
    box-sizing: border-box;
    /* padding: 3px; */
    border: 2px solid #343148; /* Add border to each row */
  }
  
  /* Even rows: image container comes first */
  .alt-row-even .alt-image-container {
    order: 1;
  }
  
  /* Even rows: content container comes second */
  .alt-row-even .alt-content-container {
    order: 2;
  }
  
  /* Odd rows: content container comes first */
  .alt-row-odd .alt-image-container {
    order: 2;
  }
  
  /* Odd rows: image container comes second */
  .alt-row-odd .alt-content-container {
    order: 1;
  }
  
  /* Container for the image */
  .alt-image-container {
    flex: 0 0 40%; /* Fixed width: 40% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Display name under image */
    padding: 10px; /* Adjust padding if necessary */
    box-sizing: border-box; /* Include border in the element's total width and height */
  }
  
  /* Container for the content */
  .alt-content-container {
    flex: 0 0 60%; /* Fixed width: 60% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Allows text to flow vertically */
    padding: 10px; /* Adjust padding if necessary */
    box-sizing: border-box; /* Include border in the element's total width and height */
  }
  
  /* Image within the image container */
  .alt-image-container img {
    width: 300px; /* Fixed width */
    height: 300px; /* Fixed height */
    object-fit: cover; /* Ensures the image covers the area without distortion */
  }
  
  /* Name text within the image container */
  .alt-image-container p {
    margin-top: 10px; /* Add margin between image and name */
    font-weight: bold; /* Make name bold */
    text-align: center; /* Center align the name */
  }
  
  /* Content text within the content container */
  .alt-content-container p {
    font-size: 18px;
    line-height: 1.6;
    margin: 0;
    text-align: justify;
    max-width: 100%; /* Ensure text doesn't overflow */
  }
  
  /* Media query for responsive design */
  @media (max-width: 768px) {
    .alt-row {
      flex-direction: column;
      min-height: auto; /* Adjust height for mobile */
    }
  
    /* Image container order for mobile view */
    .alt-row-even .alt-image-container,
    .alt-row-odd .alt-image-container {
      order: 1;
      flex: 0 0 auto; /* Adjust for mobile view */
    }
  
    /* Content container order for mobile view */
    .alt-row-even .alt-content-container,
    .alt-row-odd .alt-content-container {
      order: 2;
      flex: 0 0 auto; /* Adjust for mobile view */
    }
  
    /* Image size adjustment for mobile view */
    .alt-image-container img {
      width: 100%; /* Ensure image fits container in mobile view */
      height: auto; /* Maintain aspect ratio */
    }
  }
  