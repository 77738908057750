.mobile-menu-open {
    display: block;
  }
  .logo-header{
    margin-left: -160px;
    margin-top: -10%;
    width: 450px;

}

.nav2{
    display: flex;
    margin-left: 80px;
}

.logonav{
    height: 80px;
    width: 500px !important;
    
}
  @media (max-width: 1280px) {
    .box-navigation {
      display: none;
    }
    .logo-header{
        margin-left: -30px;
        margin-top: -10%;
        width: 220px;
    }
    .logonav{
        height: 80px;
        width: 200px !important;
        
    }
    .nav2{
        display: flex;
        margin-left: -20px;
        padding-top: 1.5% !important;
    }
    .mobile-button {
      padding: 0 8px;
      line-height: 40px;
      border: solid 1px rgb(242, 242, 242);
      gap: 9px;
      background-color: rgb(242, 242, 242);
      color: var(--main);
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin: 2px;
    }
  }
  @media (max-width: 1024px) {
    .box-navigation {
      display: none;
    }
    .logo-header{
        margin-left: 20%;
        margin-top: -20px;
        width: 600px;
        height: auto;
    }
    .logonav{
        height: 80px;
        width: 200px !important;
        
    }
    .nav2{
        display: flex;
        margin-left: -20px;
    }
    .mobile-button {
      padding: 0 8px;
      line-height: 40px;
      border: solid 1px rgb(242, 242, 242);
      gap: 9px;
      background-color: rgb(242, 242, 242);
      color: var(--main);
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin: 2px;
    }
  }

  @media (max-width: 768px) {
    .box-navigation {
      display: none;
    }
    .logo-header{
        margin-left: 50px;
        margin-top: -20px;
        width: 220px;
        
    }
    .mobile-button {
      padding: 0 8px;
      line-height: 40px;
      border: solid 1px rgb(242, 242, 242);
      gap: 9px;
      background-color: rgb(242, 242, 242);
      color: var(--main);
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin: 2px;
    }
  }

  @media only screen and (min-width: 780px) and (max-width: 1200px) {
    .box-navigation {
      display: none;
    }
    .logo-header{
        margin-left: 20%;
        margin-top: -20px;
        width: 600px;
        height: auto;
    }
    .logonav{
        height: 80px;
        width: 200px !important;
        
    }
    .nav2{
        display: flex;
        margin-left: -20px;
    }
    .mobile-button {
      padding: 0 8px;
      line-height: 40px;
      border: solid 1px rgb(242, 242, 242);
      gap: 9px;
      background-color: rgb(242, 242, 242);
      color: var(--main);
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin: 2px;
    }
}
.my_row{
  background-color: #3989659e;
  color: white;
}


/* Sticky Navbar */
.sticky-navbar {
    position: sticky;
    top: 0; /* Sticks to the top of the viewport */
    z-index: 1000; /* Ensures it's above other elements */
    background-color: white; /* Keeps a consistent background color */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Adds a slight shadow for visual clarity */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Optional: Shrink the navbar on scroll for a sleeker effect */
  .sticky-navbar.scrolled {
    padding: 10px 15px; /* Shrink padding on scroll */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add a stronger shadow */
    background-color: #f9f9f9; /* Change the background color on scroll */
  }
.row1 {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    margin: 0 auto;
}  















