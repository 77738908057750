/* .read-more-content {
    overflow: hidden;
    height: 0;
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
}

.read-more-content.open {
    height: auto;
    opacity: 1;
} */



/* Doctorteam.css */

/* Other existing styles */

/* Style for the Read More button */
.team-text button {
    border-radius: 12px; /* Adjust the radius to make the button's corners more or less rounded */
    padding: 10px 20px; /* Adjust padding as needed */
    background-color: #24444f; /* Adjust background color as needed */
    color: white; /* Text color */
    border: none; /* Remove border */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    margin-top: 10px;
}

.team-text button:hover {
    background-color: #24444f; /* Darker shade for hover effect */
}

/* Style for the Read More content */
.read-more-content {
    overflow: hidden;
    height: 0;
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
}

.read-more-content.open {
    height: auto;
    opacity: 1;
}
.team .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center align rows */
    align-items: center;    /* Vertically align items */
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center align items in each row */
    width: 100%;
    margin: 0 auto;
}

.team-item {
    margin: 15px; /* Add some spacing between team members */
    max-width: 300px; /* Optional: limit the width for consistent box sizing */
}
