.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    width: 50px;
    height: 50px;
    background-color: #24444f;
    color: white;
    border: none;
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-to-top-button.visible {
    opacity: 1;
  }
  
  .scroll-to-top-button i {
    margin-right: 0;
  }
  
  .scroll-to-top-button:hover {
    background-color: rgb(32, 5, 56);
  }
  