
















/* General styles */
.slider11-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 60px;
}

@media (max-width: 767.98px) {
  .slider11-container {
    margin-top: 30px;
  }
}

.slider11 {
  display: flex;
  height: 50vh; /* Increased height for desktop view */
}

.slider11-left {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; 
  padding: 0 10px; 
  position: relative; /* Ensure the image is positioned relative to this div */
}

.slider11-right {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slider11-bg-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 10px;
}

.slider11-caption {
  position: relative;
  color: black; /* Change to black to be visible */
  text-align: center;
  display: flex;
  flex-direction: column;
}

.slider11-caption h2,
.slider11-caption p {
  margin: 0; /* Remove default margin */
}

.slider11-desktop-caption {
  display: flex; 
  flex-direction: column;
  max-width: 100%; /* Ensure the text doesn't overflow */
  margin-left: 350px; /* Adjust as needed for proper alignment */
  margin-top: 150px;
}

.k {
  margin-top: -60px; /* Adjust the top margin to move the h1 higher */
  margin-bottom: 20px; /* Adjust the bottom margin for spacing between h1 and h2 */
  font-size: 2rem; /* Adjust font size as needed */
  color: #24444f; /* Optional: change the color for better visibility */
}

.slider11-desktop-caption h1 {
  display: block; /* Show heading in desktop view */
}

.slider11-mobile-caption {
  display: none; /* Hide caption in mobile view */
}

.slider11-prev, .slider11-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent; /* No background color */
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.slider11 h2 {
  font-size: 2rem;
}

.slider11 p {
  font-size: 1.5rem;
}

.slider11-prev {
  left: 10px;
}

.slider11-next {
  right: 10px;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .slider11 {
    flex-direction: column;
    height: auto;
  }

  .slider11 h2 {
    font-size: 2.5rem;
  }

  .slider11 p {
    font-size: 1.5rem;
  }

  .slider11-left {
    display: none; /* Hide the left section in mobile view */
    padding: 0 20px;
    text-align: center;
  }

  .slider11-bg-image {
    width: 100%;
    height: 350px; /* Increased height for mobile view */
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .slider11-desktop-caption {
    display: none; /* Hide caption in desktop view */
  }

  .slider11-mobile-caption {
    display: block; /* Show caption in mobile view */
    text-align: center;
    padding: 10px;
    margin-top: -20px;
  }

  .slider11-prev, .slider11-next {
    top: 50%; /* Center vertically */
    bottom: auto; /* Ensure bottom is not set */
    transform: translateY(-50%);
    padding: 8px;
  }

  .slider11-prev {
    left: 20px;
  }

  .slider11-next {
    right: 20px;
  }

  /* Styles for call box */
  .slider11-call-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #24444f;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-bottom: 10px;
    margin-top: -20px;
  }

  .slider11-call-box a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-weight: bold;
  }

  .slider11-call-box a svg {
    margin-right: 5px;
  }
}

@media (min-width: 769px) {
  .slider11-call-box {
    display: none; /* Hide call box in desktop view */
  }

  .slider11-desktop-caption h1 {
    display: block; /* Show heading in desktop view */
  }
}
@media only screen and (min-width: 780px) and (max-width: 1200px) {
  .diagonal-image {
    position: absolute;
    transform: rotate(-360deg); /* Rotate the image to make it diagonal */
    width: 450px; /* Adjust size as needed */
    height: 250px;
    bottom: 130px !important; /* Adjust position as needed */
    left: -30px; /* Adjust to place the image on the left side */
    z-index: -1;
  }
}
.diagonal-image {
  position: absolute;
  transform: rotate(-360deg); /* Rotate the image to make it diagonal */
  width: 450px; /* Adjust size as needed */
  height: 250px;
  bottom: 40px; /* Adjust position as needed */
  left: -30px; /* Adjust to place the image on the left side */
  z-index: -1;
}

@media (max-width: 768px) {
  .diagonal-image {
    display: none; /* Hide image in mobile view */
  }
}
