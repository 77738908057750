/* Slider styles */
.slider {
  width: 100%;
  height: 90vh;
  position: relative;
  overflow: hidden;
  background-color: var(--color-dark);
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}

@media screen and (min-width: 600px) {
  .slide img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slider {
    margin-top: -50px;
  }
}

.content {
  position: absolute;
  text-align: center;
  color: #000000;
  top: 50%;
  left: 50%;
  opacity: 0;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  animation: slide-up 1s ease 0.15s;
  animation-fill-mode: forwards;
  h2 {
    font-size: 3.5rem;
    color: #343148;
  }
  p {
    color: #343148;
    font-size: 2rem;
  }
}

@keyframes slide-up {
  0% {
    visibility: visible;
    top: 23rem;
  }
  100% {
    visibility: visible;
    top: 17rem;
  }
}

@media screen and (max-width: 600px) {
  .content {
    width: 90%;
    padding: 0.5rem;
    color: #fff;
  }

  .content h2 {
    font-size: 3rem;
    color: #343148;
  }
  p {
    color: #343148;
  }
}

.content > * {
  color: #343148;
  margin-bottom: 1rem;
}

.current {
  opacity: 1;
  transform: translateX(0);
}

.current .content {
  opacity: 1;
}

.arrow {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 10%;
  background: transparent;
  color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  margin-left: -10px;
}

.arrow:hover {
  background: #fff;
}

.next {
  right: 1.5rem;
  color: rgb(255, 255, 255);
}

.prev {
  left: 1.5rem;
  color: rgb(255, 255, 255);
}

hr {
  height: 60px;
  background: #343148;
  width: 50%;
}

/* Hero section styles */
.hero {
  position: relative;
  width: 100%;
  margin-bottom: 45px;
  padding: 90px 0;
  background: #F7CAC9;
  overflow: hidden;
  height: 630px;
}

.hero .container-fluid {
  padding: 0;
}

.hero .hero-image {
  position: relative;
  text-align: right;
}

.hero .hero-image img {
  max-width: 100%;
  max-height: 100%;
  margin-top: -130px;
}

.hero .hero-text {
  position: relative;
  padding-left: 75px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.hero .hero-text h1 {
  color: #343148;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero .hero-text p {
  color: #343148;
  font-size: 18px;
  margin-bottom: 25px;
}

.hero .hero-btn .btn {
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #F7CAC9;
  background: #343148;
  border-radius: 0;
  transition: .3s;
}

.hero .hero-btn .btn:hover {
  color: #343148;
  background: #ffffff;
}

.hero .hero-btn .btn:first-child {
  margin-right: 10px;
}

/* Desktop and tablet view */
@media (min-width: 992px) {
  .hero .hero-text {
    order: 1;
  }
  .hero .hero-image {
    order: 2;
  }

  .hero .hero-image img {
    height: 500px;
    width: 700px;
    margin-top: -130px;
  }
}

/* Tablet view */
@media (max-width: 991.98px) {
  .hero {
    width: 100%;
    background: #F7CAC9;
    height: 630px;
  }
  .hero .hero-text {
    padding: 0 15px;
  }

  .hero .hero-text h1 {
    font-size: 35px;
  }

  .hero .hero-text p {
    font-size: 16px;
  }

  .hero .hero-text .btn {
    padding: 12px 30px;
    letter-spacing: 1px;
  }
}

/* Mobile view */
@media (max-width: 767.98px) {
  .hero {
    width: 100%;
    background: #F7CAC9;
    height: 750px;
  }

  .hero-call-box {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #343148;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
    z-index: 1;
    width: 100%;
    border: #000000 2px solid;
   
  }

  .hero-call-box a {
    display: flex;
    align-items: center;
    margin: 0 10px;
    text-decoration: none;
    color: white;
    font-weight: bold;
  }

  .hero-call-box a svg {
    margin-right: 5px;
  }

  .hero .hero-text {
    order: 2;
    padding: 0 15px;
  }

  .hero .hero-image {
    order: 1;
    text-align: center;
    margin-top: 120px;
  }

  .hero .hero-text h1 {
    font-size: 30px;
    font-weight: 600;
  }

  .hero .hero-text p {
    font-size: 14px;
  }

  .hero .hero-text .btn {
    padding: 10px 15px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .hero .hero-image img {
    max-width: 100%;
    max-height: 100%;
  }
}

/* Small mobile view */
@media (max-width:575.98px) {
  .hero {
  width: 100%;
  background: #F7CAC9;
  height: 750px;
  }
  .hero .hero-text h1 {
  font-size: 25px;
  font-weight: 600;
  }
  .hero .hero-text p {
  font-size: 14px;
  }
  .hero .hero-text .btn {
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  }
  }
  