/* Add this CSS to your styles */
.form-control, .custom-select, textarea {
    width: 100% !important;
    
    box-shadow: none !important;
    background-color: white !important; /* Ensure all inputs have a white background */
    margin-bottom: 10px; /* Add some spacing between form elements */
  }
  
  textarea {
    resize: none;
    height: 100px; /* You can adjust this height as needed */
  }
  
  .form-row .form-group {
    padding: 0; /* Remove padding from form-group */
  }
  
  /* Add this CSS to your styles */
  .divider-vertical {
    border-left: 10px solid #000000;
    height: 55px;
    position: absolute;
    left: calc(50% - 0.5px); /* Center the divider between the two inputs */
    top: 0;
  }
  
  